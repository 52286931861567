import http from "../utils/request"


//查询条码列表
export function listOrder(data) {
    return http({
        url: '/admin/order/list',
        method: 'post',
        data: data
    })
}

//查询条码列表
export function orderDetail(data) {
    return http({
        url: '/admin/order/detail',
        method: 'post',
        data: data
    })
}

//标记异常订单
export function setSign(data) {
    return http({
        url: '/admin/order/sign',
        method: 'post',
        data: data
    })
}

//标记异常订单
export function videoPlayer(data) {
    return http({
        url: '/admin/order/video',
        method: 'post',
        data: data
    })
}