<template>
  <div class="app-container">
    <div class="searchForm_box">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item label="店鋪">
          <el-select
            style="width: 120px"
            @change="getOrderList"
            v-model="searchForm.shop_id"
            :clearable="true"
            :filterable="true"
            placeholder="請選擇店鋪"
          >
            <el-option
              v-for="item in shopList"
              :key="item.shop_id"
              :label="item.shop_name"
              :value="item.shop_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="狀態">
          <el-select
            style="width: 100px"
            clearable
            v-model="searchForm.order_status"
          >
            <el-option
              v-for="item in dataScopeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="異常">
          <el-select style="width: 90px" clearable v-model="searchForm.is_sign">
            <el-option
              v-for="item in orderSign"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="來源">
                    <el-select
                            style="width: 120px"
                            clearable
                            v-model="searchForm.order_source">
                        <el-option
                                v-for="item in orderSource"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"></el-option>
                    </el-select>
                </el-form-item> -->

        <el-form-item>
          <el-input
            placeholder="請輸入內容"
            v-model="searchForm.searchTxt"
            class="input-with-select"
          >
            <el-select
              v-model="searchForm.searchChoose"
              slot="prepend"
              placeholder="請選擇"
              style="width: 100px"
            >
              <el-option label="訂單ID" value="order_id"></el-option>
              <el-option label="訂單編號" value="order_no"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="創建時間">
          <el-date-picker
            style="width: 330px"
            v-model="dateRange"
            type="datetimerange"
            align="right"
            range-separator="-"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索
          </el-button>
        </el-form-item>
        <el-form-item
          >訂單金額：<font style="color: red; font-weight: bold">{{ sum }}</font
          >元</el-form-item
        >
      </el-form>
    </div>
    <el-table
      row-key="Id"
      :data="barcodeList"
      border
      class="table"
      v-loading="loading"
    >
      <el-table-column label="店鋪" prop="shop_name" align="center">
        <template slot-scope="scope">
          <span class="clickDetialBtn" @click="shopOrder(scope.row)">{{
            scope.row.shop_name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="訂單編號"
        style="position: relative"
        prop="order_no"
        width="150"
        align="center"
      >
        <template slot-scope="scope">
          <span class="clickDetialBtn" @click="orderRowClick(scope.row)">{{
            scope.row.order_no
          }}</span>
          <span
            v-if="scope.row.is_sign == 1"
            style="
              color: red;
              font-size: 8px;
              position: absolute;
              right: 5px;
              top: 0px;
            "
            >異常</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="付款時間"
        prop="pay_time"
        align="center"
        width="160"
      >
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.pay_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="數量"
        prop="buy_num"
        align="center"
        width="60"
      ></el-table-column>
      <el-table-column
        label="訂單金額"
        prop="order_price"
        align="center"
      ></el-table-column>
      <el-table-column
        label="實付金額"
        prop="pay_price"
        align="center"
      ></el-table-column>
      <el-table-column
        label="利潤"
        prop="profit"
        align="center"
      ></el-table-column>
      <el-table-column
        label="創建時間"
        prop="create_time"
        width="160"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
                    label="雲值守"
                    prop="cloud_watch_status"
                    width="80"
                    align="center"
            >
                <template slot-scope="scope">
                    <el-tag
                            style="padding: 0px 4px; line-height: 20px; height: 20px"
                            v-if="scope.row.cloud_watch_status == 1"
                            type="success">是
                    </el-tag>
                    <el-tag
                            style="padding: 0px 4px; line-height: 20px; height: 20px"
                            v-else
                            type="primary">否
                    </el-tag
                    >
                </template>
            </el-table-column> -->
      <el-table-column
        label="異常"
        prop="stock_sale_status"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_sign"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="1"
            :inactive-value="2"
            @change="setSign(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <!-- <el-table-column
                    label="支付方式"
                    prop="pay_type"
                    align="center"
                    width="90"
            >
                <template slot-scope="scope">
                    {{ scope.row.pay_type }}
                </template>
            </el-table-column> -->
      <el-table-column label="訂單狀態" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.order_status == 10" style="color: red"
            >待付款</span
          >
          <span v-if="scope.row.order_status == 20" style="color: green"
            >支付成功</span
          >
          <span v-if="scope.row.order_status == 30" style="color: #ccc"
            >訂單關閉</span
          >
          <span v-if="scope.row.order_status == 40" style="color: blue"
            >退款成功</span
          >
        </template>
      </el-table-column>
      <!-- <el-table-column label="視頻" width="50" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="video_history(scope.row)">回放</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 詳情 -->
    <el-drawer
      title="訂單信息"
      :visible.sync="orderInfoDrawer"
      size="900px"
      direction="rtl"
    >
      <div class="orderInfo" v-loading="orderInfoLoading">
        <div class="orderInfo_box">
          <div class="orderContent">
            <p>
              <span class="orderInfoLabel">訂單號：</span
              ><span>{{ orderInfo.order_no }}</span>
            </p>
            <p>
              <span class="orderInfoLabel">訂單狀態：</span>
              {{
                orderInfo.order_status == 10
                  ? "待付款"
                  : orderInfo.order_status == 20
                  ? "支付成功"
                  : orderInfo.order_status == 30
                  ? "訂單關閉"
                  : orderInfo.order_status == 40
                  ? "退款成功"
                  : ""
              }}
            </p>
          </div>
          <div class="orderContent">
            <p>
              <span class="orderInfoLabel">下單時間：</span
              ><span>{{ orderInfo.create_time }}</span>
            </p>
            <p>
              <span class="orderInfoLabel">商品總金額：</span
              ><span>{{ orderInfo.order_price }}</span>
            </p>
          </div>
          <div class="orderContent">
            <!-- <p>
              <span class="orderInfoLabel">優惠券抵扣金額：</span
              ><span>{{ orderInfo.coupon_money }}</span>
                        </p>
                        <p>
              <span class="orderInfoLabel">積分抵扣金額：</span
              ><span>{{ orderInfo.points_money }}</span>
                        </p> -->
          </div>
        </div>
        <div class="orderInfo_box">
          <div class="orderTitle">支付信息</div>
          <div class="orderContent">
            <p>
              <span class="orderInfoLabel">支付單：</span
              ><span>{{ orderInfo.transaction_id }}</span>
            </p>
            <p>
              <span class="orderInfoLabel">支付方式：</span
              ><span>{{ orderInfo.pay_type }}</span>
            </p>
          </div>
          <div class="orderContent">
            <p>
              <span class="orderInfoLabel">支付時間：</span
              ><span>{{ orderInfo.pay_time }}</span>
            </p>
            <p>
              <span class="orderInfoLabel">支付金額：</span
              ><span>{{ orderInfo.pay_price }}</span>
            </p>
          </div>
          <div class="orderContent">
            <p>
              <!-- <span class="orderInfoLabel">支付來源：</span
              ><span>{{
                orderInfo.order_source == 1
                  ? "大屏機"
                  : orderInfo.order_source == 2
                  ? "收銀POS"
                  : orderInfo.order_source == 3
                  ? "掃碼購"
                  : ""
              }}</span> -->
            </p>
            <p>
              <span class="orderInfoLabel">同步時間：</span
              ><span>{{ orderInfo.update_time }}</span>
            </p>
          </div>
        </div>
        <div class="orderInfo_box">
          <div class="orderTitle">商品信息</div>
          <div
            class="shopContent"
            v-for="(item, index) in orderInfo.goodList"
            :key="index"
          >
            <el-container>
              <el-container>
                <el-aside width="100px" v-if="item.img == ''">
                  <img
                    style="
                      width: 80px;
                      height: 80px;
                      text-align: center;
                      margin: auto;
                      overflow: hidden;
                    "
                    :src="require('@/assets/image/default.jpg')"
                    alt=""
                  />
                </el-aside>
                <el-aside width="100px" v-else>
                  <img
                    style="
                      width: 80px;
                      height: 80px;
                      text-align: center;
                      margin: auto;
                      overflow: hidden;
                    "
                    :src="item.img"
                    alt=""
                  />
                </el-aside>
                <el-container>
                  <el-header>
                    <div>{{ item.goods_name }}</div>
                    <div>商品ID：{{ item.goods_no }}</div>
                    <div>標籤號：{{ item.tag_no }}</div>
                    <div>價格：{{ item.goods_price }}</div>
                    <div>
                      數量：<b>{{ item.total_num }}</b>
                    </div>
                  </el-header>
                  <el-footer></el-footer>
                </el-container>
              </el-container>
            </el-container>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 添加或修改部門對話框 -->
    <el-dialog
      :title="video_title"
      :visible.sync="video_open"
      width="650px"
      append-to-body
    >
      <el-form ref="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="監控位置" prop="shop_id">
              <el-select v-model="channel_no" filterable remote>
                <el-option
                  v-for="item in channel"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" @click="submitVideo">確 定</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div id="video-container" style="width: 600px; height: 400px"></div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { listOrder, orderDetail, setSign, videoPlayer } from "@/api/order";
import { listShop } from "@/api/shop";
import { formatDate } from "../../utils/tools";

export default {
  name: "index",
  data() {
    return {
      //以下是視頻回放相關
      video_title: "視頻回放",
      video_open: false,
      channel: [],
      channel_no: 1,
      order_id: 0,
      player: "",
      //
      shopList: [],
      orderInfoDrawer: false,
      orderInfo: "",
      orderInfoLoading: false,
      dateRange: [],
      title: "",
      loading: true,
      searchForm: {
        order_status: null,
        searchTxt: "",
        searchChoose: "order_no",
        order_no: "",
        order_id: "",
        start_time: "",
        end_time: "",
        shop_id: "",
        order_source: "",
        is_sign: "",
      },
      page: 1,
      pageSize: 50,
      total: 0,
      sum: 0, //訂單金額匯總
      barcodeList: [],
      // 是否顯示彈出層
      open: false,
      orderSign: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "2",
          label: "否",
        },
      ],
      //訂單來源1大屏機 2收銀POS 3掃碼購
      orderSource: [
        {
          value: "1",
          label: "大屏機",
        },
        {
          value: "2",
          label: "收銀POS",
        },
        {
          value: "3",
          label: "掃碼購",
        },
      ],
      // 數據範圍選項
      dataScopeOptions: [
        {
          value: "10",
          label: "待付款",
        },
        {
          value: "20",
          label: "支付成功",
        },
        {
          value: "30",
          label: "訂單關閉",
        },
        // {
        //     value: "40",
        //     label: "退款成功",
        // },
      ],
    };
  },
  //     filters: {
  //         // 0-澳門中銀手機銀行  1-支付寶 2-微信 4-極易付 5-豐付寶 6-工銀E支付 7-澳門通 8-國際銀行
  //         payTyp(val) {
  //         return {0:'澳門中銀手機銀行',1:'支付寶'}[val]
  //     }
  //   },
  computed: {
    timeDefault() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [
        formatDate(start, "Y-m-d") + " 00:00:00",
        formatDate(end, "Y-m-d") + " 23:59:59",
      ];
    },
  },
  methods: {
    //視頻切換
    submitVideo() {
      videoPlayer({
        order_id: this.order_id,
        channel_no: this.channel_no,
      }).then((res) => {
        this.channel = res.data.channelList;
        player = new EZUIKit.EZUIKitPlayer({
          id: "video-container", // 視頻容器ID
          accessToken: res.data.token,
          url: res.data.videoUlr,
          template: "pcRec",
          width: 600,
          height: 400,
        });
        window.player = player;
      });
    },
    //視頻回放
    video_history(row) {
      this.video_title = "視頻回放：" + row.order_no;
      this.video_open = true;
      this.order_id = row.order_id;
      videoPlayer({ order_id: row.order_id }).then((res) => {
        this.channel = res.data.channelList;
        player = new EZUIKit.EZUIKitPlayer({
          id: "video-container", // 視頻容器ID
          accessToken: res.data.token,
          url: res.data.videoUlr,
          template: "pcRec",
          width: 600,
          height: 400,
        });
        window.player = player;
      });
    },
    shopOrder(row) {
      this.searchForm.shop_id = row.shop_id;
      this.page = 1;
      this.getOrderList();
    },
    getShopList() {
      let params = {
        pageSize: 999,
        status: 2,
      };
      listShop(params).then((response) => {
        this.shopList = response.data.list;
      });
    },
    getOrderList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        order_id:
          this.searchForm.searchChoose == "order_id"
            ? this.searchForm.searchTxt
            : "",
        order_no:
          this.searchForm.searchChoose == "order_no"
            ? this.searchForm.searchTxt
            : "",

        order_status: this.searchForm.order_status,
        shop_id: this.searchForm.shop_id,
        order_source: this.searchForm.order_source,
        is_sign: this.searchForm.is_sign,
      };
      if (this.dateRange) {
        params["start_time"] = this.dateRange[0];
        params["end_time"] = this.dateRange[1];
      }
      this.loading = true;
      listOrder(params)
        .then((response) => {
          this.barcodeList = response.data.list;
          this.total = response.data.count;
          this.sum = response.data.sum;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrderList();
    },
    handleQuery() {
      this.page = 1;
      this.getOrderList();
    },
    resetQuery() {
      this.page = 1;
      this.searchForm = {
        searchChoose: "goods_no",
      };
    },
    // 獲取詳情
    orderRowClick(val) {
      this.orderInfoDrawer = true;
      // this.orderInfoLoading = true;
      orderDetail({ order_id: val.order_id })
        .then((response) => {
          this.orderInfo = response.data;
        })
        .catch(() => {});
    },
    // 取消按鈕
    cancel() {
      this.open = false;
      this.reset();
    },
    setSign(row) {
      let is_sign = row.is_sign;
      let txt = "";
      let old_is_sign = "";
      if (is_sign == 1) {
        txt = "標記異常為";
        old_is_sign = 0;
      } else {
        txt = "取消標記異常";
        old_is_sign = 2;
      }
      let params = {
        is_sign: row.is_sign,
        order_id: row.order_id,
      };
      this.$modal
        .confirm("確定要<span style='color:red'>" + txt + "</span>訂單？")
        .then(function () {
          return setSign(params);
        })
        .then(() => {
          this.$modal.msgSuccess("操作成功");
        })
        .catch(() => {
          row.is_sign = old_is_sign;
        });
    },
  },
  created() {
    this.dateRange = this.timeDefault;
    this.getOrderList();
    this.getShopList();
  },
};
</script>
<style lang="scss" scoped>
.agentManage {
  padding: 10px;

  > .searchForm_box {
    padding: 10px;
    background: #fff;
    margin: 10px 0;

    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.orderInfo {
  > .orderInfo_box {
    border-bottom: 1px solid #eeeeee;
    padding: 10px 20px;

    > .orderContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      .orderInfoLabel {
        color: #808695;
        display: inline-block;
        width: 120px;
        text-align: right;
      }
    }
  }

  // > .shopContent {
  // }
}

.avatar-uploader-icon {
  color: #8c939d;
  width: 150px;
  height: 150px;
  text-align: center;
  padding: 40px 0 0 0;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.clickDetialBtn {
  color: rgb(24, 144, 255);
  cursor: pointer;
}
</style>
